<template>
  <div class="summit">
    <img class="background" src="../../assets/image/background.png" alt srcset />
    <header>
      <img src="../../assets/image/logo.png" alt="" srcset="" />
    </header>
    <van-divider :style="dividerStyle">历届峰会回顾</van-divider>
    <div class="cell" @click="review(1)">
      <div class="title">第一届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(2)">
      <div class="title">第二届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(3)">
      <div class="title">第三届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(4)">
      <div class="title">第四届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(5)">
      <div class="title">第五届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(6)">
      <div class="title">第六届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(7)">
      <div class="title">第七届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(8)">
      <div class="title">第八届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <div class="cell" @click="review(9)">
      <div class="title">第九届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div>
    <!-- <div class="cell" @click="review(10)">
      <div class="title">第十届峰会回顾</div>
      <div class="right">
        <van-icon name="play" color='#fff' size="16px" />
      </div>
    </div> -->
    <div class="footer-fill"></div>
    <el-footer :index="1"/>
  </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
export default {
  components: {
    "el-footer": footer
  },
  data() {
    return {
      dividerStyle: {
        color: "#FFFFFF",
        borderColor: "#fff",
        padding: "0 18px",
        "font-size": "16px",
      },
    };
  },
  methods:{
    review(index){
      //直接调用$router.push 实现携带参数的跳转
        this.$router.push({
          path: `/review/${index}`,
        })
    }
  }
};
</script>

<style lang="less" scoped>
.summit {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .box {
    height: 46px;
  }
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar {
    background-color: transparent;
  }
  .cell{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      padding: 0 20px;
      box-sizing: border-box;
      width: 92%;
      margin: 0 4% 10px 4%;
      background-color: #693853;
      border: 1px solid #fff;
      .title{
          color: #fff;
          font-size: 16px;
      }
  }
}
.footer-fill{
  height: 37px;
}
</style>